import { FC } from 'react'
import { styled } from '@liftfoils/styles'
import { Container, Link, Media, PortableText } from '@liftfoils/components'
import { ArrowTopRight } from '@liftfoils/icons'
import { useTranslation } from 'react-i18next'
import { SectionPressProps } from '@liftfoils/models'
import { validateLink } from '../Helpers/validateLink'

const Wrap = styled('div', {
  position: 'relative',
  $py: 'L',
  display: 'grid',
  gridGap: '$10',
  justifyItems: 'center',
})

const Title = styled('h2', {
  lift_font: 'heading01',
  textAlign: 'center',
  color: '$gray300',
  maxWidth: '540px',
  '.highlighted': {
    color: '$gray500',
  },
})
const Article = styled('div', {
  bg: 'white',
  borderRadius: '$r1',
  p: '$9',
  display: 'grid',
  gridTemplateRows: '36px 1fr auto',
  gridGap: '$8',
  minHeight: '340px',
  scrollSnapAlign: 'start',
  '@lg': {
    minHeight: '420px',
  },
})

const ArticleTitle = styled('div', {
  lift_font: 'heading02',
  color: '$gray300',
  '.highlighted': { color: '$gray500' },
})

const LogoWrap = styled('div', {
  position: 'relative',
  maxWidth: '50%',
  opacity: 0.8,
  img: {
    objectPosition: 'left center',
  },
})
const Slider = styled('div', {
  position: 'relative',
  width: '100vw',
  display: 'flex',
  overflowX: 'scroll',
  scrollSnapType: 'x mandatory',
  scrollPaddingLeft: '$containerMargin',
  mx: '-$containerMargin',
  px: '$containerMargin',
  overflowY: 'hidden',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '@lg': {
    mx: '$0',
    px: '$0',
    width: 'auto',
  },
})
const Articles = styled('div', {
  position: 'relative',
  gridGap: '$5',
  display: 'grid',
  gridAutoColumns: '280px',
  gridAutoFlow: 'column',
  '@lg': {
    gridAutoColumns: 'unset',
    gridAutoFlow: 'unset',
    overflowX: 'hidden',
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
})
const LinkWrap = styled('div', { justifySelf: 'end', mt: '$10' })
const Root = styled('div', { bg: '$gray100' })

export const SectionPress: FC<SectionPressProps> = ({ title, articles }) => {
  const { t } = useTranslation('press')
  return (
    <Root>
      <Container>
        <Wrap>
          <Title>
            <PortableText value={title} />
          </Title>

          {articles && (
            <Slider>
              <Articles>
                {articles.map((article, i) => (
                  <Article key={i}>
                    {article.logo && (
                      <LogoWrap>
                        <Media
                          {...article.logo}
                          layout={'fill'}
                          objectFit={'contain'}
                          sizes="(min-width: 1300px) 25vw, (min-width: 740px) 50vw, 100vw"
                          alt={article.logo.alt ?? ''}
                        />
                      </LogoWrap>
                    )}

                    <ArticleTitle>
                      <PortableText value={article.title} />
                    </ArticleTitle>

                    {validateLink(article.link) && (
                      <LinkWrap>
                        <Link
                          size={'medium'}
                          appearance={'articleLink'}
                          {...article.link}
                          target="_blank"
                        >
                          <span>{t('readArticle')}</span> <ArrowTopRight />
                        </Link>
                      </LinkWrap>
                    )}
                  </Article>
                ))}
              </Articles>
            </Slider>
          )}
        </Wrap>
      </Container>
    </Root>
  )
}
